import React, { useState, useEffect } from "react";
import { Button, notification, Typography } from "antd";
import { useParams } from "react-router-dom";
import { getSmileSecure, postSmileSecure } from "../../util/api_util";

// If you want to toggle development, you can do so manually in the AWS console.
// Go to the `smile-partners` table, or hopefully someday, the `smile-partners-dev`
// table. Search for the partner ID you intend to update, and flip the boolean at
// smile_secure.enabled.development.
interface SmileSecureJson {
  enabled: {
    development: boolean;
    test: boolean;
    production: boolean;
  };
  error?: string;
}

interface GetSmileSecureResponse {
  name: string;
  partner_id: string;
  smile_secure: smileSecure;
  error?: string;
}

enum Environment {
  Production = "production",
  Sandbox = "sandbox",
}

const SmileSecure = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [sandboxConfirm, setSandboxConfirm] = useState<boolean>(false);
  const [productionConfirm, setProductionConfirm] = useState<boolean>(false);
  const [sandbox, setSandbox] = useState<boolean | undefined>();
  const [production, setProduction] = useState<boolean | undefined>();
  const [company, setCompany] = useState<string | undefined>();
  const [api, contextHolder] = notification.useNotification();
  const [error, setError] = useState<string | undefined>();
  const [message, setMessage] = useState<string | undefined>();
  const { partnerId } = useParams<string>();

  const getData = async () => {
    const response: GetSmileSecureResponse = await getSmileSecure({
      partnerId,
    });
    if (response?.error) {
      setMessage();
      setError(response.error);
    }
    setSandbox(response?.smile_secure?.enabled?.test);
    setProduction(response?.smile_secure?.enabled?.production);
    setCompany(response?.name);
    setLoading(false);
  };

  const toggle = async (env: Environment, value: boolean) => {
    setDisabled(true);
    const response: SmileSecureJson = await postSmileSecure({
      enabled: value,
      environment: env === "sandbox" ? "test" : env,
      partner_id: partnerId,
    });
    if (response?.error) {
      setMessage();
      setError(response.error);
    } else {
      setError();
      setMessage(
        `Smile Secure updated, ${env} is now set to ${value ? "enabled" : "disabled"}`,
      );
    }
    setSandbox(response?.smile_secure?.enabled?.test);
    setProduction(response?.smile_secure?.enabled?.production);
    if (env === "sandbox") {
      setSandboxConfirm(false);
    }
    if (env === "production") {
      setProductionConfirm(false);
    }
    setDisabled(false);
  };

  useEffect(() => {
    if (!error && !message) {
      return;
    }
    api[error ? "error" : "success"]({
      description: error || message,
      message: error ? "Error" : "Success",
      props: {
        "data-testid": "ss-message",
      },
    });
  }, [error, message]);

  useEffect(() => {
    if (production !== undefined) {
      return;
    }
    getData();
  }, [production]);

  if (loading) {
    return <div className={loading ? "loader" : ""} data-testid="loader" />;
  }
  return (
    <div>
      {contextHolder}
      <Typography.Title className="h3" level={2} data-testid="title">
        {`Smile Secure Partner: ${partnerId} ${company || ""}`}
      </Typography.Title>
      <div>
        <Typography.Paragraph>
          Sandbox:{" "}
          <Typography.Text
            type={sandbox === true ? "success" : "danger"}
            data-testid="sandbox-value"
          >
            {sandbox === true ? "enabled" : "disabled"}
          </Typography.Text>
        </Typography.Paragraph>
        {sandboxConfirm ? (
          <div>
            <Button
              type="primary"
              onClick={() => toggle("sandbox", !sandbox)}
              data-testid="sandbox-confirm"
            >
              Confirm
            </Button>
            <Button
              onClick={() => setSandboxConfirm(false)}
              data-testid="sandbox-cancel"
            >
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            type="primary"
            disabled={disabled}
            onClick={() => setSandboxConfirm(true)}
            data-testid="sandbox-button"
            className="edit-partner__product-buttons"
          >
            Toggle Sandbox
          </Button>
        )}
      </div>
      <div className="mt-5">
        <Typography.Paragraph>
          Production:{" "}
          <Typography.Text
            type={production === true ? "success" : "danger"}
            data-testid="production-value"
          >
            {production === true ? "enabled" : "disabled"}
          </Typography.Text>
        </Typography.Paragraph>
        {productionConfirm ? (
          <div>
            <Button
              type="primary"
              onClick={() => toggle("production", !production)}
              data-testid="production-confirm"
            >
              Confirm
            </Button>
            <Button
              onClick={() => setProductionConfirm(false)}
              data-testid="production-cancel"
            >
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            type="primary"
            disabled={disabled}
            onClick={() => setProductionConfirm(true)}
            data-testid="production-button"
            className="edit-partner__product-buttons"
          >
            Toggle Production
          </Button>
        )}
      </div>
    </div>
  );
};

export default SmileSecure;
